/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-23 00:15:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-24 09:07:19
 */

import http from "./axios_init.js";

export default {

  /**
   * 获取所有的部门列表
   * @returns
   */
   get_depts() {
    return http.post("seller/v1/dept");
  },

  /**
   * 获取所有的部门 Tree树形结构
   * @returns
   */
  get_dept() {
    return http.post("seller/v1/dept/tree");
  },



  
};
