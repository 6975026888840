<!--
 * @Description: 选择部门人员、单选、多选
 * @Author: 琢磨先生
 * @Date: 2022-09-17 09:44:55
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-06-29 16:12:02
-->
<template>
  <div @click="onOpen" style="line-height:0;">
    <slot>
      <el-button type="primary" link :icon="`${icon ? icon : 'Plus'}`">{{
        text ? text : "添加人员"
      }}</el-button>
    </slot>
  </div>
  <el-dialog
    title="选择人员"
    v-model="visibleDialog"
    top="10vh"
    width="800px"
    draggable :destroy-on-close="true"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div style="height: 35vh">
      <el-row :gutter="20" style="height: 100%">
        <el-col :span="10" style="height: 100%">
          <el-scrollbar>
            <el-tree
              ref="tree"
              :data="dept_list"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              :props="{ children: 'children', label: 'name' }"
              default-expand-all
              @current-change="treeNodeChange"
            />
          </el-scrollbar>
        </el-col>
        <el-col :span="14" style="height: 100%">
          <el-table
            ref="empTable"
            :data="emp_list"
            row-key="id" style="height: 100%;"
            @selection-change="selectionChange"
          >
            <el-table-column
              type="selection"
              width="80"
              reserve-selection
            ></el-table-column>
            <el-table-column label="姓名" prop="chs_name">
              <template #header>
                <el-input
                  v-model="query.q"
                  placeholder="关键字查询部门人员"
                  @input="inputChange"
                ></el-input>
              </template>
              <template #default="scope"
                >{{ scope.row.chs_name }}{{ scope.row.mobile_phone }}</template
              >
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 20px">
      <el-alert type="info" :closable="false" title="已选择人员">
        <el-tag
          style="margin: 0 10px 10px 0"
          v-for="(item, i) in selection_list"
          :key="item.id"
          closable
          @close="onRemove(i)"
          >{{ item.chs_name }}</el-tag
        >
      </el-alert>
    </div>
    <template #footer>
      <el-button
        type="primary"
        @click="onConfirm"
        :disabled="selection_list.length == 0"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import dept_api from "@/http/dept_api";
export default {
  data() {
    return {
      visibleDialog: false,
      dept_list: [],
      query: {
        dept_id: "",
        q: "",
      },
      emp_list: [],
      //选中的人员
      selection_list: [],
    };
  },
  props: ["single", "icon", "title", "text"],
  emits: ["success",'before'],
  //   watch: {
  //     max: {
  //       handler() {
  //         console.log(this.max);
  //         if (!isNaN(this.max) && this.max > 0) {
  //           this.max_number = parseInt(this.max);
  //         }
  //       },
  //       immediate: true,
  //     },
  //   },
  created() {},
  methods: {
    onOpen() {
      this.visibleDialog = true;
      this.$emit('before');
      this.loadDept();
    },

    /**
     * 获取部门数据
     */
    loadDept() {
      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.dept_list = res.data;
          if (this.dept_list.length > 0) {
            this.$nextTick(() => {
              if (this.$refs.tree) {
                this.$refs.tree.setCurrentKey(this.dept_list[0].id);
              }
            });
            this.query.dept_id = this.dept_list[0].id;
            this.loadData();
          }
        }
      });
    },

    /**
     * 部门选择
     */
    treeNodeChange(node) {
      this.query.dept_id = node.id;
      this.loadData();
    },
    /**
     * 输入查询
     */
    inputChange() {
      this.loadData();
    },
    /**
     * 选择更改
     */
    selectionChange(nodes) {
      if (this.single && nodes.length > 1) {
        //单选
        var node = nodes[nodes.length - 1];
        this.$refs.empTable.clearSelection();
        this.$refs.empTable.toggleRowSelection(node, true);
        this.selection_list = [node];
        return;
      }
      this.selection_list = nodes;
    },
    /**
     * 移除选择
     */
    onRemove(i) {
      var node = this.selection_list.splice(i, 1)[0];
      this.$refs.empTable.toggleRowSelection(node, false);
    },
    /**
     * 加载人员
     */
    loadData() {
      this.$http.post("seller/v1/emp/by_dept", this.query).then((res) => {
        if (res.code == 0) {
          this.emp_list = res.data;
        }
      });
    },

    /**
     * 确定选择
     */
    onConfirm() {
      if (this.selection_list.length > 0) {
        var list = this.selection_list.map((x) => Object.assign({}, x));
        this.$emit("success", list);
        this.visibleDialog = false;
      }
    },
  },
};
</script>

<style  scoped>
</style>