<!--
 * @Description: 新增、修改流程数据
 * @Author: 琢磨先生
 * @Date: 2022-09-24 09:18:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-28 00:52:49
-->
<template>
  <el-dialog
    :title="title"
    draggable
    v-model="visibleDialog"
    width="800px"
    @closed="onClosed"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item
        :label="item.label"
        v-for="item in form_list"
        :key="item"
        :prop="`${item.sequence}`"
      >
        <el-input v-model="form[`${item.sequence}`]" v-if="item.element == 'text'"></el-input>
        <el-input
          v-model="form[`${item.sequence}`]"
          type="textarea"
          v-if="item.element == 'textarea'"
          rows="5"
        ></el-input>
        <el-select v-model="form[`${item.sequence}`]" placeholder v-if="item.element == 'select'">
          <el-option v-for="x in item.items" :key="x.name" :label="x.name" :value="x.name"></el-option>
        </el-select>
        <el-date-picker
          v-model="form[`${item.sequence}`]"
          type="datetime"
          format="YYYY-MM-DD HH:mm"
          value-format="YYYY-MM-DD HH:mm"
          placeholder="datetime"
          v-if="item.element == 'datetime'"
        ></el-date-picker>
        <upload-obs-file
          v-if="item.element == 'file'"
          @change="fileUploadChange"
          :hook="`${item.sequence}`"
        ></upload-obs-file>
      </el-form-item>
      <el-form-item label="审批流程">
        <div class="flow_container" v-if="template">
          <div class="flow_box" v-for="node in node_list" :key="node">
            <div class="flow_header" v-if="node.type == 0">
              <el-icon color="#409EFF" :size="20">
                <UserFilled />
              </el-icon>
              <span>审批人</span>
            </div>
            <div class="flow_header" v-if="node.type == 10">
              <el-icon color="#67C23A" :size="20">
                <Promotion />
              </el-icon>
              <span>抄送人</span>
            </div>
            <div class="flow_body">
              <div v-if="node.type == 0" class="approver">
                <div class="user" v-for="(emp, i) in node.emp_list" :key="emp.id">
                  <el-tag
                    :closable="true"
                    size="large"
                    @close="approver_emp_list.splice(i, 1)"
                  >{{ emp.chs_name }}</el-tag>
                  <el-icon>
                    <Right />
                  </el-icon>
                </div>

                <emp-choose
                  @success="addApproveEmp"
                  :single="true"
                  @before="beforeApproverChoose(node)"
                >
                  <el-button size="small" type="primary" v-if="node.is_self_notify" icon="Plus"></el-button>
                </emp-choose>
              </div>
              <div v-if="node.type == 10" class="notify">
                <span>
                  <el-tag
                    type="info"
                    v-for="dept in node.notify_dept_list"
                    :key="dept.id"
                    style="margin: 0 10px 10px 0"
                  >{{ dept.name }}</el-tag>
                </span>
                <el-tag
                  v-for="(emp, i) in node.notify_emp_list"
                  :key="emp.id"
                  :closable="emp.closable"
                  style="margin: 0 10px 10px 0"
                  @close="notify_emp_list.splice(i, 1)"
                >{{ emp.chs_name }}</el-tag>
                <emp-choose @success="addNotifyEmp" @before="beforeNotifyChoose(node)">
                  <el-button size="small" circle v-if="node.is_self_notify" icon="Plus"></el-button>
                </emp-choose>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import EmpChoose from "@/views/components/emp_choose.vue";
import UploadObsFile from "@/views/upload/upload_obs_file.vue";
export default {
  components: {
    UploadObsFile,
    EmpChoose,
  },
  data() {
    return {
      title: "",
      visibleDialog: false,
      form: {},
      rules: {},
      //模板表单列表
      form_list: [],
      //审批节点列表
      node_list: [],
    };
  },
  props: ["template"],
  emits: ["success", "close"],
  watch: {
    template: {
      handler() {
        if (this.template) {
          console.log(this.template);
          this.visibleDialog = true;
          this.form = {};
          this.rules = {};
          this.form_list = [];
          this.node_list = JSON.parse(JSON.stringify(this.template.node_list));
          this.template.form_list.forEach((element) => {
            this.form_list.push(element);
            this.form[`${element.sequence}`] = "";
            if (element.is_required) {
              this.rules[`${element.sequence}`] = [
                { required: true, message: "必填", trigger: "blur" },
              ];
            }
          });
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    onClosed() {
      this.$emit("close");
    },

    /**
     * 上传后文件回调
     */
    fileUploadChange(files, hook) {
      this.form[`${hook}`] = files.map((x) => {
        return {
          file_url: x.file_url,
          name: x.name,
          ext: x.ext,
        };
      });
    },

    /**
     * 选择抄送人弹出之前
     */
    beforeNotifyChoose(o) {
      console.log(o);
      if (!o.notify_emp_list) {
        o.notify_emp_list = [];
      }
      this.crt_notify_node = o;
    },

    /**
     * 添加抄送人
     */
    addNotifyEmp(list) {
      list.forEach((x) => {
        if (!this.crt_notify_node.notify_emp_list.find((o) => o.id == x.id)) {
          x.closable = true;
          this.crt_notify_node.notify_emp_list.push(x);
        }
      });
    },

    /**
     *
     */
    beforeApproverChoose(o) {
      if (!o.emp_list) {
        o.emp_list = [];
      }
      this.crt_node = o;
    },
    /**
     *
     */
    addApproveEmp(list) {
      // console.log(list);
      this.crt_node.emp_list = this.crt_node.emp_list.concat(list);
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form_list.forEach((item) => {
            item.files = [];
            if (item.element == "file") {
              item.files = this.form[`${item.sequence}`];
            } else {
              item.value = this.form[`${item.sequence}`];
            }
          });
          var model = {
            template_id: this.template.id,
            form_content_list: this.form_list.map((x) => {
              return {
                element: x.element,
                label: x.label,
                value: x.value,
                files: x.files,
              };
            }),
            node_list: this.node_list,
          };
          for (let i = 0; i < this.node_list.length; i++) {
            var node = this.node_list[i];
            if (node.type == 0 && node.appoint_type == 2) {
              if (node.emp_list.length <= 0) {
                this.$message.error("请添加审批人");
                return;
              }
            }
          }

          console.log(model);

          this.saving = true;
          this.$http
            .post("seller/v1/workflow/edit", model)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$emit("success");
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style  scoped>
.flow_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.flow_header {
  display: flex;
  align-items: center;
}
.flow_body {
  position: relative;
  padding-left: 20px;
  padding-bottom: 20px;
}
.flow_body::before {
  display: block;
  width: 2px;
  height: 100%;
  background: var(--el-border-color-light);
  content: "";
  position: absolute;
  z-index: 1;
  left: 8px;
}
.flow_box:last-child .flow_body::before {
  display: none;
}

.flow_body .approver {
  display: flex;
  align-items: center;
}

.flow_body .approver .user {
  position: relative;
  display: flex;
  align-items: center;
}

.flow_body .notify {
  display: flex;
  flex-wrap: wrap;
}
</style>